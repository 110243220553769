@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: light) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}

body {
	color: rgb(var(--foreground-rgb));
	background: linear-gradient(
			to bottom,
			transparent,
			rgb(var(--background-end-rgb))
		)
		rgb(var(--background-start-rgb));
}

@font-face {
	font-family: 'NonBreakingSpaceOverride';
	src:
		url(data:application/font-woff2;charset=utf-8;base64,d09GMgABAAAAAAMoAA0AAAAACDQAAALTAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP0ZGVE0cGh4GYACCahEICjx3CywAATYCJANUBCAFhiEHgWwbXQfILgpsY+rQRRARwyAs6uL7pxzYhxEE+32b3aeHmifR6tklkS9hiZA0ewkqGRJE+H7/+6378ASViK/PGeavqJyOzsceKi1s3BCiQsiOdn1r/RBgIJYEgCUhbm/8/8/h4saPssnTNkkiWUBrTRtjmQSajw3Ui3pZ3LYDPD+XG2C3JA/yKAS8/rU5eNfuGqRf4eNNgV4YAlIIgxglEkWe6FYpq10+wi3g+/nUgvgPFczNrz/RsTgVm/zfbPuHZlsuQECxuyqBcQwKFBjFgKO8AqP4bAN9tFJtnM9xPcbNjeXS/x1wY/xU52f5W/X1+9cnH4YwKIaoRRAkUkj/YlAAeF/624foiIDBgBmgQBeGAyhBljUPZUm/l2dTvmpqcBDUOHdbPZWd8JsBAsGr4w8/EDn82/bUPx4eh0YNrQTBuHO2FjQEAGBwK0DeI37DpQVqdERS4gZBhpeUhWCfLFz7J99aEBgsJCHvUGAdAPp4IADDCAPCEFMGpMZ9AQpTfQtQGhLbGVBZFV8BaqNyP68oTZgHNj3M8kBPfXTTC9t90UuzYhy9ciH0grVlOcqyCytisvbsERsEYztiznR0WCrmTksJwbSNK6fd1Rvr25I9oLvctUoEbNOmXJbqgYgPXEHJ82IUsrCnpkxh23F1rfZ2zcRnJYoXtauB3VTFkFXQg3uoZYD5qE0kdjDtoDoF1h2bulGmev5HbYhbrjtohQSRI4aNOkffIcT+d3v6atpaYh3JvPoQsztCcqvaBkppDSPcQ3bw3KaCBo1f5CJWTZEgW3LjLofYg51MaVezrx8xZitYbQ9KYeoRaqQdVLwSEfrKXLK1otCWOKNdR/YwYAfon5Yk8O2MJfSD10dPGA5PIJJQMkah0ugMJiv6x4Dm7LEa8xnrRGGGLAg4sAlbsA07sAt76DOsXKO3hIjtIlpnnFrt1qW4kh6NhS83P/6HB/fl1SMAAA==)
			format('woff2'),
		url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAUQAA0AAAAACDQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAAE9AAAABwAAAAchf5yU0dERUYAAATYAAAAHAAAAB4AJwAbT1MvMgAAAaAAAABJAAAAYJAcgU5jbWFwAAACIAAAAF4AAAFqUUxBZ2dhc3AAAATQAAAACAAAAAgAAAAQZ2x5ZgAAApAAAAAyAAAAPL0n8y9oZWFkAAABMAAAADAAAAA2Fi93Z2hoZWEAAAFgAAAAHQAAACQOSgWaaG10eAAAAewAAAAzAAAAVC7TAQBsb2NhAAACgAAAABAAAAAsAOQBAm1heHAAAAGAAAAAHQAAACAAWQALbmFtZQAAAsQAAAF6AAADIYvD/Adwb3N0AAAEQAAAAI4AAADsapk2o3jaY2BkYGAA4ov5mwzj+W2+MnCzXwCKMNzgCBSB0LfbQDQ7AxuI4mBgAlEAFKQIRHjaY2BkYGD3+NvCwMDBAALsDAyMDKhAFAA3+wH3AAAAeNpjYGRgYBBl4GBgYgABEMnIABJzAPMZAAVmAGUAAAB42mNgZlJhnMDAysDCKsKygYGBYRqEZtrDYMT4D8gHSmEHjgUFOQwODAqqf9g9/rYwMLB7MNUAhRlBcsxBrMlASoGBEQAj8QtyAAAAeNrjYGBkAAGmWQwMjO8gmBnIZ2NA0ExAzNjAAFYJVn0ASBsD6VAIDZb7AtELAgANIgb9AHjaY2BgYGaAYBkGRgYQSAHyGMF8FgYPIM3HwMHAxMDGoMCwQIFLQV8hXvXP//9AcRCfAcb///h/ygPW+w/vb7olBjUHCTCyMcAFGZmABBO6AogThgZgIUsXAEDcEzcAAHjaY2BgECMCyoEgACZaAed42mNgYmRgYGBnYGNgYAZSDJqMgorCgoqCjECRXwwNrCAKSP5mAAFGBiRgyAAAi/YFBQAAeNqtkc1OwkAUhU/5M25cEhcsZick0AwlBJq6MWwgJkAgYV/KAA2lJeUn+hY+gktXvpKv4dLTMqKycGHsTZNv7px7z50ZAFd4hYHjdw1Ls4EiHjVncIFnzVnc4F1zDkWjrzmPW+NNcwGlzIRKI3fJlUyrEjZQxb3mDH2fNGfRx4vmHKqG0JzHg6E0F9DOlFBGBxUI1GEzLNT4S0aLuTtsGAEUuYcQHkyg3KmIum1bNUvKlrjbbAIleqHHnS4iSudpQcySMYtdFiXlAxzSbAwfMxK6kZoHKhbjjespMTioOPZnzI+4ucCeTVyKMVKLfeAS6vSWaTinuZwzyy/Dc7vaed+6KaV0kukdPUk6yOcctZPvvxxqksq2lEW8RvHjMEO2FCl/zy6p3NEm0R9OFSafJdldc4QVeyaaObMBO0/5cCaa6d9Ggyubxire+lEojscdjoWUR1xGOy8KD8mG2ZLO2l2paDc3A39qmU2z2W5YNv5+u79e6QfGJY/hAAB42m3NywrCMBQE0DupWp/1AYI7/6DEaLQu66Mrd35BKUWKJSlFv1+rue4cGM7shgR981qSon+ZNwUJ8iDgoYU2OvDRRQ99DDDECAHGmGCKmf80hZSx/Kik/LliFbtmN6xmt+yOjdg9GztV4tROnRwX/Bsaaw51nt4Lc7tWaZYHp/MlzKx51LZs5htNri+2AAAAAQAB//8AD3jaY2BkYGDgAWIxIGZiYARCESBmAfMYAAR6AEMAAAABAAAAANXtRbgAAAAA2AhRFAAAAADYCNuG)
			format('woff');
}

@font-face {
	font-family: 'myriad_proregular';
	src:
		url('../assets/fonts/myriad_pro_regular-webfont.woff2') format('woff2'),
		url('../assets/fonts/myriad_pro_regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

ul {
	list-style: disc !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='lateef']::before,
.ql-picker.ql-font .ql-picker-item[data-value='lateef']::before {
	font-family: 'myriad_proregular', sans-serif;
	content: 'myriad_proregular';
}

@font-face {
	font-family: 'parkingregular';
	src: url('../assets/fonts/parking-regular-webfont.eot');
	src:
		url('../assets/fonts/parking-regular-webfont.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/parking-regular-webfont.woff2') format('woff2'),
		url('../assets/fonts/parking-regular-webfont.woff') format('woff'),
		url('../assets/fonts/parking-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

h1,
h2 {
	font-family: 'parkingregular', sans-serif;
	font-weight: 400;
	padding-bottom: 20px;
	margin: 0;
}
h1 {
	font-size: 60px;
}

h2 {
	font-size: 40px;
}

p {
	font-family: 'myriad_proregular', sans-serif;
	font-weight: 400;
	font-size: 25px;
	padding-bottom: 25px;
	margin: 0;
	line-height: 1.75;
	color: #000;
}

.black-text > p,
.black-text > h1,
.black-text > h2 {
	color: #000;
}

.black-text > h2 {
	border-bottom: 2px solid #000;
	margin-top: 20px;
	margin-bottom: 60px;
}

.black-text > a,
.black-text > a > p,
.black-text > p > a {
	color: rgb(0, 108, 117) !important;
}

.cast-modal p,
.cast-modal h1,
.cast-modal h2,
.cast-modal h3,
.cast-modal h4,
.cast-modal h5,
.cast-modal h6 {
	color: #fff;
}

.show-info {
	position: relative;
}

.ant-collapse-content-box {
	padding: 0 !important;
}

.text-shadow-none p {
	text-shadow: none;
}

.text-shadow p, .text-shadow {
	text-shadow: 0 2px 2px rgba(0, 0, 0, 1);
}

.text-shadow-md p {
	text-shadow:
		0 4px 8px rgba(0, 0, 0, 0.15),
		0 2px 4px rgba(0, 0, 0, 0.08);
}

.text-shadow-lg p {
	text-shadow:
		0 15px 30px rgba(0, 0, 0, 0.11),
		0 5px 15px rgba(0, 0, 0, 0.08);
}

.text-shadow-none p {
	text-shadow: none;
}

.text-shadow-light p {
	text-shadow: 1px 2px 2px rgba(255, 255, 255, 0.65);
}

.text-shadow-sharp p {
	text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.85);
}

.text-shadow-light-sharp p {
	text-shadow: 1px 3px 3px rgb(255, 255, 255);
}

.text-shadow-light-md p {
	text-shadow: 0 4px 8px rgba(255, 255, 255, 0.65);
}

.text-shadow-light-lg p {
	text-shadow:
		0 15px 30px rgba(255, 255, 255, 0.11),
		0 5px 15px rgba(255, 255, 255, 0.08);
}

.force-text-white p {
	color: #fff !important;
}

.background-tiled {
	background-image:
			linear-gradient(rgba(0, 73, 82, 0.85), rgba(0, 73, 82, 0.85)),
				/* Partially opaque overlay */ url('../assets/background_tile.png'); /* Tiled background image */
	background-color: rgba(0, 0, 0, 0.9);
	background-repeat: repeat;
	background-attachment: fixed; /* Keeps the background fixed */
}

.background-tiled-tan {
	background-image:
		linear-gradient(rgba(184, 152, 100, 0.85), rgba(184, 152, 100, 0.85)),
		/* Partially opaque overlay */ url('../assets/background_tile.png'); /* Tiled background image */
	background-color: rgba(0, 0, 0, 0.9);
	background-repeat: repeat;
}

.background-tiled-white {
	background-image:
			linear-gradient(rgba(190, 190, 190, 0.8), rgba(190, 190, 190, 0.8)),
			url('../assets/background_tile.png');
	background-color: rgba(0, 0, 0, 0.9);
	background-repeat: repeat;
}
